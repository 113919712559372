:root {
  --primary-color: #0b4141;
  --secondary-color: #d9f2f2;
  --ternary-color: #0f5757;
  --quad-color: #2d8686;
  --penta-color: #a6d9d9;
  --hexa-color:#d9f2f2;
  --accent-color:#75bdbd;
  --font-color: #222222;
  --gray-color:#545454;
  --lightgray-color:#e6e6e6;
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p{
  font-size: 16px;
  color: var(--font-color);
  line-height: 1.5;
  text-align: justify;
}
span{
  font-size: 14px;
}
a{
  font-size: 16px;
  color: var(--primary-color);
  line-height: 1.5;
  text-decoration: none;
  font-weight: bold;
}
h1, h2, h3,h4,h5,h6{
  color: var(--primary-color);
  font-weight: bold;
}
h1{
  font-size: 40px;
}
h2{
  font-size: 25px;
}
h3{
  font-size: 20px;
}
h4{
  font-size: 18px;
}
h5{
  font-size: 16px;
}
h6{
  font-size: 15px;
}
.bg-dark{
  background-color: #222222 !important;
}
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark p
 {
  color: #fff !important;
}
.bg-dark #block-nsc-content .content a{
  color:#fff;
}
.bg-dark .document-block span{
  color:#fff;
}
.bg-dark .list-group-item{
  background-color: #222222 !important;
}
.bg-dark .menuItem .linkBlock .link-img{
  fill:var(--primary-color) !important;
}
.bg-dark .menuItem .linkBlock .link-title{
  color:var(--primary-color) !important;
}
.bg-dark .footer-section{
  background: #222222;
}
.bg-dark .search-box span{
  color:#fff !important;
}
.bg-dark .search-box .header-text {
  color:#fff !important;
}
.bg-dark .search-icon{
  fill:#fff !important;
}
.bg-dark .search-box {
  border-top: 1px solid #fff;
  border-bottom: 5px solid #fff;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
.bg-dark .mic{
  fill:#fff !important;
}
.bg-dark .camera path{
  fill:#fff !important;
}
.bg-dark svg {
  fill:#fff !important;
}

.bg-dark .border-right {
  border-right: 2px solid #fff;
}
.bg-dark .access-tabs{
  background-color: #222222;
}
.bg-dark #nav-tabContent .list-block {
  border: 1px dashed #fff;
}
.bg-dark #nav-organization-chart .chart li a {
  color:#fff;
}
.bg-dark #nav-organization-chart .chart ul ul::before {
  border-left: 1px solid #fff !important;
}

.bg-dark #nav-organization-chart .chart li::after {
  border-left: 1px solid #fff !important;
}
.bg-dark #nav-organization-chart .chart li::before, .bg-dark .chart li::after{
  border-color: #fff !important;
}
.bg-dark #nav-organization-chart .chart li:last-child::after{
  border: 0 none !important;
}
.bg-dark #nav-organization-chart .chart li a>span{
  color:#fff;
}
.bg-dark .contact-address {
  border:1px dashed #fff;
}
.bg-dark .contact-address a {
  color:#fff;
}
.bg-dark .tabLowerblock .link-block .mail-text{
  color:#fff;
}
.bg-dark #about-block .block-border .serial{
  color:#fff;
}
.bg-dark .map{
  filter:invert(1);
}
.bg-dark .contact-address svg{
  fill:#fff !important;
}
.bg-dark .accordion-body{
  background-color: #222222;
}
.bg-dark .accordion-button:not(.collapsed){
  background-color: #222222 !important;
  color:#fff !important;
}
.bg-dark .tabLowerblock li{
  color:#fff;
}
.bg-dark .accordion-button{
  background-color: #222222;
  color:#fff;
}
.bg-dark .document-block a{
  color:#fff !important;
}
.bg-dark .sitemap-text a{
  color:#fff !important; 
}
.bg-dark .sitemap-text svg {
  fill:#fff !important;
}
.bg-dark #aboutus-block .tabLowerblock {
  color:#fff;
}
.bg-dark .search-box-circular{
  border: 1px solid #fff !important;
}
.bg-dark .mail-text, .bg-dark .phone-text,.bg-dark .mode-text,.bg-dark #related-links-block li a{
  color:#fff;
}
.bg-dark .cardBlock, .bg-dark .right-top-block,.bg-dark .cardBlockSocial{
  background-color: #222;
}
.bg-increase{
  font-size: 87.5%;
}
.bg-increase .pm-quote {
  margin-left:96px;
}
.bg-increase p{
  font-size: 18px;
}
.bg-increase a{
  font-size: 18px;
}
.bg-increase h3{
  font-size: 19px;
}
.bg-increase .pm-note{
  font-size: 13px;
  width:185px;
}
.bg-increase .pm-quote {
  line-height: 1.4;
}
.bg-increase span{
  font-size:18px;
}
.bg-increase #nav-tabContent li {
  font-size: 18px;
}
.bg-increase h2{
  font-size: 26px;
}
.bg-increase h1{
  font-size: 38px !important;
}
.bg-increase #nav-organization-chart .chart li a {
  font-size: 17px;
}
.bg-increase .accordion-button {
  font-size: 1.1rem !important;
}
.bg-dark .form-control::placeholder{
  color:#fff;
  opacity: 1;
  background:transparent;
}
.bg-dark .filterBox{
  color:#fff;
  border: 1px solid #fff !important; 
}
#block-nssta-content{
  min-height: calc(100vh - 370px);
}
.innerblock{
  background-image: url(./assets/nssta_bg.jpg);
  background-size: cover;
  background-position: 0px 20%;
  position: relative;
  padding: 150px 20px 10px 81px;
}
.innerblock::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to top, rgba(3, 50, 50, 0.95), rgba(3, 50, 50, 0.2)); */
  background: linear-gradient(to right, rgb(0 60 60), rgba(3, 50, 50, 0.2));
  z-index: 1;
}
.innerblock .navigation-link {
  font-size: 16px;
  color: #fff;
  z-index: 1;
  position: relative;
  margin-bottom: 8px;
}
.innerblock .navigation-link a {
  color: #fff !important;
  z-index: 2;
}
.innerblock .navigation-title{
  z-index: 2;
  position: relative;
  color:#fff;
  margin-bottom: 28px;
}
.content-wrapper {
  z-index: 8;
}
#aboutus-block .tab-block .tabInner {
  background: var(--primary-color) !important;
  border-radius: 10px;
  width: 92%;
  margin: 0 auto;
}
#aboutus-block .tab-block2 .tabInner {
  background: var(--primary-color) !important;
  border-radius: 10px;
  width: 88%;
  margin: 0 auto;
}
.marginLeft{
  margin-left: 72px;
}
#aboutus-block .tab-block {
  margin-top: -24px;
  z-index: 9;
  background-color: transparent;
}
#aboutus-block .tab-block2 {
  z-index: 9;
  background-color: transparent;
}
#aboutus-block .react-tabs__tab-list{
  border-bottom: none !important;
  padding: 0px 20px;
}
#aboutus-block .react-tabs__tab-list li{
  border: none !important;
  background: transparent !important;
  color: #fff !important;
  padding: 15px 40px;
}
#aboutus-block .react-tabs__tab-list li a{
  color: #fff !important;
}
#aboutus-block .react-tabs__tab-list{
  margin:0px !important;
}
#aboutus-block .react-tabs__tab:focus:after{
  background: transparent !important;
}
#aboutus-block .react-tabs__tab.react-tabs__tab--selected{
  background-color: var(--primary-color) !important;
  border: none !important;
  color: #fff !important;
  background: url(./assets/active.png) !important;
  background-repeat: no-repeat !important;
  background-size: 30px !important;
  background-position: 0% 50% !important;
  fill: #fff !important;
}
#aboutus-block .tabLowerblock {
  width: 92%;
  margin: 0 auto;
}

#nav-tabcontent .nav.nav-tabs{
  display: flex;
  flex-direction: column;
  gap:2;
}
#nav-tabcontent .nav-tabs{
  border-bottom:0px !important;
}
#nav-tabcontent .nav-link{
  border:none !important;
  width:100% !important;
  text-align: left !important;
  border-radius: 5px;
  font-size: 16px;
  background-color: var(--lightgray-color);
  color: var(--primary-color);
  font-weight: 700;
  margin-bottom: 8px;
  padding-right: 44px;
}
#nav-tabcontent .nav-link.active{
  background-color: var(--secondary-color) !important;
  color: var(--primary-color);
  font-weight: 700;
}
.nav-item{
  position: relative;
}
#nav-tabcontent .nav-link.active:after{
  content:'';
  width: 30px;
  height:30px;
  position: absolute;
  top:5px;
  right:10px;
  background: url(./assets/right-arrow.png) !important;
  background-repeat: no-repeat !important;
  background-size: 30px !important;
  background-position: 96% 50% !important;
}
.profile-tab1{
  background-color: var(--secondary-color);
  border-radius: 15px;
  padding: 10px;
}
.meet{
  position: absolute;
  top: 13px;
  left: 40px;
  text-align: center;
  width: 73%;
  background: var(--penta-color);
  font-size: 18px;
  z-index: 9;
}
.meet span{
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
}
.adg-detail{
  text-align: center;
  font-size: 18px;
  color: var(--primary-color);
}
.adg-profile{
  float: right; 
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #fff;
}
.training-icon{
  fill: var(--primary-color);
}
.icon-block{
  padding:10px;
  background-color: var(--secondary-color);
  border-radius: 10px;
}
.card-block{
  background-color: var(--primary-color);
  padding:14px 0px;
  border-radius: 8px;
}
.card-block p{
  color:#fff;
}
.innernav-block{
  display:flex;
  flex-wrap: normal;
  gap: 1.5rem;
}
.contact-address {
  border: 1px dashed var(--primary-color);
  padding: 15px;
  border-radius: 10px;
}
.links-svg{
  fill: var(--primary-color);
  width: 20px;
}
.list-group-item{
  border: none !important;
}
.related-link-block li{
  padding:0 !important;
  padding-bottom: 10px !important;
}
.contact-address {
  border: 1px dashed var(--primary-color);
  padding: 27px;
  border-radius: 10px;
}
.contact-address .address-detail {
  font-weight: 600;
  text-align: justify;
}
.contact-address svg {
  fill: var(--primary-color) !important;
  width: 22px;
  height: 22px;
}
.map {
  width: 100%;
  height: 333px;
  border-radius: 6px;
  border: 1px dashed var(--primary-color) !important;
}
.contact-address a {
  color: var(--primary-color);
  font-weight: 700;
  padding-left: 5px;
}
.contact-text {
  padding-top: 40px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-color);
}
.container{
  max-width: 1366px !important;
}
.profile-tab2{
  background: var(--penta-color);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.profileimage{
  border: 3px solid var(--primary-color);
  padding: 15px;
  border-radius: 20px;
  position: relative;
}
.table-headbg {
  background-color: var(--accent-color);
  border-radius: 8px;
}
.tabLowerblock .text-format {
  color: var(--primary-color);
  font-weight: bold;
}
.block-border{
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  padding: 6px 0px;
  margin-top: 6px;
}
.bg-tablerow{
  background-color: var(--hexa-color);
}
/* .block-border:nth-child(even) {
  border: 2px solid var(--secondary-color);
  background-color: #fff;
  border-radius: 10px;
  padding: 6px 0px;
  margin-top: 6px;
} */
.nssta-layout{
  width: 100%;
  height: 350px;
  border-radius: 10px;
}
.ril__caption, .ril__toolbar{
  background-color: transparent !important;
}
#infrastructure-slider .sliderImg{
  width:100%;
  height:376px;
  border-radius: 20px;
}
#infrastructure-slider .slick-dots{
  bottom:-44px !important;
}
#infrastructure-slider .table-head{
  background-color:var(--secondary-color);
}
.bg-block{
  background-color: var(--secondary-color);
}
.active-sports{
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border: none !important;
  font-weight: bold;
}
.inactive-sports{
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  font-weight: bold;
}
.download-button{
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
  border: none !important;
  font-size: 13px !important;
  font-weight: bold;
}
.feedback-block p, .feedback-block h2{
  text-align: center !important;
}
.search-box-archieves{
  width: 100%;
  border-top: 1px solid var(--primary-color);
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom: 2px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  padding: 5px;
}
.search-box-archieves input{
  outline:none !important;
  border: none !important;
  box-shadow: none !important;
}
.search-box-archieves input:focus{
  outline:none !important;
  border: none !important;
  box-shadow: none !important;
}
#nav-tabcontent, ul, li, ::before, ::after{
  position: relative;
  margin: 0;
  padding: 0;
  
  border: 0 none;
    
  -webkit-transition: all ease .4s;
  -moz-transition: all ease .4s;
    transition: all ease .4s;
}

#nav-tabcontent .chart li:first-child::before, #nav-tabcontent .chart li:last-child::after {
  border: 0 none;
}

#nav-tabcontent h1 {
  padding-top: 40px;
    
  color: var(--primary-color);
  text-align: center;
  font-size: 1.8rem;
     
    text-shadow: rgba(0,0,0,0.6) 1px 0, rgba(0,0,0,0.6) 1px 0, rgba(0,0,0,0.6) 0 1px, rgba(0,0,0,0.6) 0 1px;
}

#nav-tabcontent .chart {
  margin: 20px auto;
  min-height: auto;
}

#nav-tabcontent .chart ul {
  position: relative;
  padding-top: 40px; 
  display: flex;
    justify-content: center;
}
#nav-tabcontent .chartLine{
  border-top:1px var(--primary-color) solid !important;
  opacity: 1;
}
#nav-tabcontent .chartButton{
    display: inline-block;
    padding: 5px 10px;
    border-radius: 11px;
    border: 3px solid var(--secondary-color);
    text-decoration: none;
    color: var(--primary-color);
    font-family: "Noto Sans", sans-serif;
    font-size: 15px;
    outline-color: var(--secondary-color);
    outline-style: solid;
    outline-width: 3px;
    width: 100%;
    text-align: center;
}
#nav-tabcontent .chart li {
  position: relative;
  padding: 42px 199px 0 3px;
  float: left;
  text-align: center;
  list-style-type: none;
}
#nav-tabcontent .chart .last-li::after {
  left: 36% !important;
  width: 65% !important;
}

#nav-tabcontent .chart li::before, .chart li::after{
  content: '';
  position: absolute; 
  top: 0; 
  right: 50%;
  width: 84%; 
  height: 40px;
  border-top: 1px solid var(--primary-color);
}

#nav-tabcontent .chart li::after{
  left: 13%;
  right: auto; 
  
  border-left: 1px solid var(--primary-color);
}
#nav-tabcontent .chart li:only-child::after, .chart li:only-child::before {
  content: '';
  display: none;
}

#nav-tabcontent .chart li:only-child{ 
  padding-top: 0; 
  padding-right:0;

}
#nav-tabcontent .chart li:last-child{ 
  padding-right:0;

}
.chart li:first-child::before, .chart li:last-child::after{
  border: 0 none;
}

#nav-tabcontent .chart li:last-child::before{
  border-right: 1px solid var(--primary-color);
  border-radius: 0 6px 0 0;
}

#nav-tabcontent .chart li:first-child::after{
    border-radius: 6px 0 0 0;
}
#nav-tabcontent .chart ul ul::before{
  content: '';
  position: absolute; top: 0; left: 50%;
  border-left: 1px solid var(--primary-color) !important;
  width: 0; 
  height: 40px;
}
#nav-tabcontent .chart li h6{
  font-weight: 700;
  color: var(--primary-color);
  font-size: 18px;
  margin-bottom: 0;
}
#nav-tabcontent .chart li a{
  display: inline-block;
  padding: 5px 10px;  
  border-radius: 11px;
  border: 3px solid var(--secondary-color);
  text-decoration: none;
  color: var(--primary-color);
  font-family: "Noto Sans", sans-serif;
  font-size: 15px;
  outline-color: var(--secondary-color);
  outline-style: solid;
  outline-width: 3px;   
  width: 100%;
}
.adgtab{
  margin-bottom: 7px;
}
.parenttab{
  width: 163px !important;
}
.level2-tab::before{
  top: -4px !important;
}
#nav-tabcontent .chart li a>span{
  color: #150202;
}
#nav-tabcontent .chart li a:hover, .chart li a:hover+ul li a,.chart li a:hover+ul li a>span{
  color: #fff !important;
  background: var(--primary-color);   
}
#nav-tabcontent .chart li a:hover h6 {
  color: #fff !important;
}
#nav-tabcontent .chart li a:hover>span {
  color:#fff;
}
#nav-tabcontent .chart img{
  width: 80px;
  height: 80px;
  border-radius: 6%;
  margin-top: -62px;
  position: relative !important;
  background: #E6E6E6;
}
.search-box-circular{
  border-radius: 10px !important;
  border: 1px solid var(--primary-color) !important;
  outline: none;
  padding: 4px !important;
  width:280px;
}
.search-box-circular svg{
  width: 35px;
  height:28px;
  margin-top: 3px;
  margin-left: 3px;
}
.search-box-circular .form-control{
  box-shadow: none !important;
  outline: none !important;
  border:none !important;
}
.search-box-circular .form-control::placeholder{
  color: var(--primary-color) !important;
}
.react-datepicker__input-container input{
  border-radius: 10px !important;
  border: 1px solid var(--primary-color) !important;
  outline: none;
  padding: 10px !important;
  width:280px;
}
#batch-picker .react-datepicker__input-container input{
  border-radius: 10px !important;
  border: 1px solid #dee2e6 !important;
  outline: none;
  padding: 10px !important;
  width:100%;
}
#admin-form .react-datepicker__input-container input{
  border-radius: 6px !important;
  border: 1px solid #dee2e6 !important;
  outline: none;
  padding: 6px !important;
  width:100%;
}
.react-datepicker__input-container input::placeholder{
  color: var(--primary-color) !important;
}
.training-block{
  margin-top:10px;
}

/******************************* Tabular Gallery Start ***********************************/

.gallery-block{
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
  padding:14px;
}
.filter-button
{
  border: none;
  border-radius: 5px;
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 30px;
  padding: 5px 10px;
  background-color: var(--lightgray-color);
  font-weight: bold;
}
.filter-button:hover,.filter-button:active, .filter-button:focus
{
  border: none;
  border-radius: 5px;
  text-align: center;
  color: var(--primary-color);
  background-color: var(--secondary-color);
}
.gallery_product img{
  height:180px;
  cursor: pointer;
}
.custom-select-container {
  width:100%;
  position: relative;
  display: inline-block;
}

.custom-select-container select {
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* Remove default arrow for Safari */
  -moz-appearance: none; /* Remove default arrow for Firefox */
  background: transparent;
  padding-right: 30px; /* Space for the icon */
}

.custom-select-container::after {
  content: '\f0d7'; /* FontAwesome caret-down icon */
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
/******************************* Tabular Gallery End ***************************************/

/******************************* Resource Gallery Start ***********************************/

.resource-block{
  
}
/* .filter-button
{
  border: none;
  border-radius: 5px;
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 30px;
  padding: 5px 10px;
  background-color: var(--lightgray-color);
  font-weight: bold;
} */
.filter-button.active
{
  border: none;
  border-radius: 5px;
  text-align: center;
  color: var(--primary-color);
  background-color: var(--secondary-color);
}
.filter-button:hover,.filter-button:focus
{
  border: none;
  border-radius: 5px;
  text-align: center;
  color: var(--primary-color);
  background-color: var(--secondary-color);
}
.resource_product img{
  height:280px;
  cursor: pointer;
}
.res-text{
  color: var(--gray-color);
  font-weight: bold;
}
/******************************* Resource Gallery End ***************************************/

.header-fixed{
  position: fixed;
  top: 0;
  left: auto;
  z-index: 999;
  width:88%;
}
.access-tabs {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.access-heading-icon {
  width: 25px !important;
}
.access-icons{
  fill: var(--primary-color) !important;
  width: 30px;
  cursor: pointer;
}
.access {
  fill: var(--primary-color) !important;
  width: 30px;
  cursor: pointer;
}
.access-tabs img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.dropdown-item{
  border: 1px solid #eaeaea !important;
}
.dropdown-item:focus{
  background-color: #fff !important;
}
.dropdown-item:hover{
  background-color: var(--light-gray-color);
}
.dropdown-menu{    
  position: absolute;
  top:70%;
  z-index: 1000;
  display: block;
  width:300px;
  padding: .5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
}
.dropdown-menu[data-bs-popper] {
  left:80% !important;
  width:20%;
  top:73%;
}
#admin-panel .dropdown-menu{    
  position: absolute;
  top:95%;
  right:0;
  right:5%;
  z-index: 1000;
  display: block;
  width:100%;
  padding: .5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15) !important;
}
#admin-panel .dropdown-item{
  border:none !important;
}
#admin-panel .divider{
  margin:0 !important;
}
.access-tabs a {
  border: 1px solid #eaeaea;
  padding: .5rem .5rem !important;
}
.mode-text{
  text-align: center;
  font-size: 12px;
  margin: 5px 0 0 0;
}
.border-left{
  border-left: 2px solid var(--primary) !important;
}


.sidebar{
  top: 0;
  left : 0;
  z-index : 100;
  overflow-y: auto;
  background: var(--primary-color);
}
.box-style{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border-left: 0.25rem solid #1cc88a !important;
}

.overlay{
  /* background-color: rgb(0 0 0 / 45%); */
  z-index: 99;
}
.list-group-item.active{
  background-color: var(--secondary-color);
  color: var(--primary-color) !important;
}
.list-group-item{
  background-color: transparent;
  /* color:#fff; */
}
.list-group-item:hover{
  background-color: var(--secondary-color);
  color:var(--primary-color);
}
.list-group-item-action:active{
  background-color: var(--secondary-color) !important;
}
.sidebar-nav .list-group-item{
  color:#fff;
}
.sidebar-nav .collapse{
  color:#fff;
  transition:height 0.3s ease-in-out;
  transition-delay: 0.1s;
}
.sidebar-nav .list-group-item:hover{
  background-color: var(--secondary-color);
  color:var(--primary-color);
}
.sidebar-nav .list-group-item-action:active, .sidebar-nav .list-group-item-action:focus{
  background-color: var(--secondary-color);
  color:var(--primary-color);
}
.sidebar-nav .list-group-item-action:active .bi-chevron-down, .sidebar-nav .list-group-item-action:focus .bi-chevron-down, .sidebar-nav .list-group-item:hover .bi-chevron-down{
  color:var(--primary-color) !important;
}
.sidebar-nav .bi-chevron-down{
  color: #ffffff !important;
}
.logo-block{
  background:#fff;
  padding:10px;
  margin:0 auto;
}
.submit-btn{
  background-color: var(--primary-color) !important;
  border:none;
  outline:none;
}
.form-block{
  border:1px dotted var(--primary-color);
}
.danger{
  color:#FF474D;
}
.bi-pencil-square ,.bi-download{
  font-size: 26px;
  cursor:pointer;
  color: var(--primary-color);
  margin-right:10px;
}

.bi-x-square{
  font-size: 26px;
  cursor:pointer;
  color: #FF474D;
  margin-right:10px;
}
.bi-list,.bi-person,.bi-chevron-down{
  color: var(--primary-color);
}
.react-confirm-alert-overlay {
  background: rgb(0 0 0 / 57%) !important;
}
.cardicon-block{
  padding: 8px;
  background: #e7e7e7;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-icon{
  font-size: 45px;
  margin-bottom: 0px;
  color: #0b4141;
}
.d-title{
  color:#d27c34;
}
.filterBox{
  border-radius: 10px !important;
  border: 1px solid var(--primary-color) !important;
  outline: none !important;
  padding: 10px !important;
  box-shadow: none !important;
}
.bi-arrow-return-right, .bi-dot{
  color:var(--primary-color);
}
.success{
  background-color: #a2ef8e47;
}
.warning{
  background-color: #ffc10736;
}
.bgdanger{
  background-color: #ff969640;
}
.word-wrap {
  word-wrap: break-word; /* Allows long words to be broken and wrapped to the next line */
  word-break: break-word; /* Ensures text wraps even within words */
  overflow-wrap: break-word; /* Ensures text wraps within words */
  white-space: normal; /* Ensures normal line breaks */
}



/******************************* Media Query START ***************************************/
@media (max-width: 767px) {
  p{
    font-size: 14px;
  }
  span{
    font-size: 13px;
  }
  a{
    font-size: 14px;
  }
  h1{
    font-size: 30px;
  }
  h2{
    font-size: 20px;
  }
  h3{
    font-size: 18px;
  }
  h4{
    font-size: 16px;
  }
  h5{
    font-size: 14px;
  }
  h6{
    font-size: 13px;
  }
  .innernav-block{
    display:flex;
    flex-wrap: wrap;
  }
  #aboutus-block .tab-block .tabInner{
    width:98%;
  }
  #nav-tabcontent .nav-link{
    font-size: 14px;
  }
  .nssta-layout{
    height: 230px;
  }
  #infrastructure-slider .slick-dots li{
    display: none !important;
  }
  #infrastructure-slider .slick-dots li:nth-child(1), #infrastructure-slider .slick-dots li:nth-child(2),  #infrastructure-slider .slick-dots li:nth-child(3){
    display: inline-block !important;
  }
  #infrastructure-slider .sliderImg{
    height:200px;
  }
  #infrastructure-slider .slick-dots{
    bottom:-31px !important;
  }
  #nav-tabcontent  .chart {
    overflow: scroll;
    overflow-x: scroll;
    overflow-y: scroll;
  }
  .resource_product img{
    height:180px;
  }
  /* .react-datepicker__input-container input{
    width:100%;
  } */
  #aboutus-block .react-tabs__tab-list li {
    border: none !important;
    background: transparent !important;
    color: #fff !important;
    padding: 8px 7px 8px 12px;
  }
  #aboutus-block .react-tabs__tab-list li a{
    font-size: 12px !important;
  }
  #aboutus-block .react-tabs__tab-list{
    padding: 0 10px !important;
  }
  #aboutus-block .react-tabs__tab.react-tabs__tab--selected{
    background-size: 10px !important;
  }
  .innerblock {
    padding: 150px 20px 10px 15px;
  }
  .sidebar{
    max-width: 18rem;
    transform : translateX(-100%);
    transition : transform 0.4s ease-out;
  }
  .sidebar.active{
    transform : translateX(0);
  }
  .active-sports{
   font-size:14px;
  }
  .inactive-sports{
    font-size:14px;
  }
  .custom-select-container {
    width:100%;
    margin-bottom:5px;
  }
  .filterBox{
    padding: 20px !important;
    border-radius: 5px !important;
  }
  .dropdown-menu {
    top:100%;
  }
  .filter-button
  {
    font-size: 12px;
    margin-bottom:10px;
  }
  .contact-address {
    padding:10px;
  }
  .download-button {
    font-size: 10px !important;
  }
}


@media (min-width: 768px) and (max-width:1366px) {
#aboutus-block .tabLowerblock {
  width: 89%;
}
#aboutus-block .tab-block .tabInner {
  width: 89%;
}

}
/******************************* Media Query End ***************************************/